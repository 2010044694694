import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import SubscribeDialogBox from './subscribeDialogBox';

class Footer extends Component {
  render() {
    const path = this.props.location.pathname;
    const showHide =
      path === '/membership' ||
      path === '/membership/order' ||
      path === '/docentlogin'
        ? 'hide'
        : ' ';
    const narrow =
      path === '/membership' ||
      path === '/membership/order' ||
      path === '/docentlogin'
        ? '-narrow'
        : ' ';
    const footerSm =
      path === '/membership' ||
      path === '/membership/order' ||
      path === '/docentlogin'
        ? 'footer-sm'
        : ' ';

    const { galleryData, contactData } = this.props;
    const { address, email, phone, social } = contactData;
    const { street, city, state, zip } = address;

    // Return empty footer if data from db not present.
    if (typeof galleryData === 'string')
      return <footer className={footerSm}></footer>;

    const dailyHoursObj = galleryData.hours.days;

    return (
      <footer className={footerSm}>
        <div className={`footer-main ${showHide}`}>
          <div className="margin-container">
            <div className="footer-main-topRow">
              <div className="footer-main-column-hours">
                <div className="footer-main-header">
                  <h4>Weekly Hours</h4>
                </div>
                <div className="footer-main-content-hours">
                  <table className="footer-main-hours">
                    <tbody>
                      {dailyHoursObj &&
                        Object.keys(dailyHoursObj).map((day) => (
                          <tr key={day}>
                            <td>{day}</td>
                            <td>
                              {dailyHoursObj[day].open} -{' '}
                              {dailyHoursObj[day].close}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>{' '}
              {/* end footer-main-column-hours. */}
              <div className="footer-main-column-mainContent">
                <div className="footer-main-header">
                  <Link to="/">
                    <span className="footer-logo">Tahoe Art League</span>
                  </Link>
                </div>
                <div className="footer-main-content-mainContent">
                  <div className="footer-main-content-mainContent-row">
                    <div className="footer-main-column-contact">
                      <p>
                        {street}
                        <br />
                        {city}
                        <br />
                        {state}
                        <br />
                        {zip}
                      </p>
                      <p>
                        {phone} <br />
                        {email}
                      </p>
                    </div>
                    <div className="footer-main-column-menu">
                      <ul>
                        <li>
                          <Link to="/gallery">Gallery</Link>
                        </li>
                        <li>
                          <Link to="/artists">Artists</Link>
                        </li>
                        <li>
                          <Link to="/events">Events</Link>
                        </li>
                        {/* <li><Link to="/art-around-town">Art Around Town</Link></li> */}
                        <li>
                          <Link to="/about">About</Link>
                        </li>
                        <li>
                          <Link to="/contact">Contact</Link>
                        </li>
                      </ul>
                      <div className="footer-social">
                        {social.facebook && (
                          <a
                            href={social.facebook}
                            className="footer-icon-fb"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <FontAwesomeIcon icon={faFacebookSquare} />
                          </a>
                        )}
                        {social.twitter && (
                          <a
                            href={social.twitter}
                            className="footer-icon-twitter"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <FontAwesomeIcon icon={faTwitter} />
                          </a>
                        )}
                        {social.instagram && (
                          <a
                            href={social.instagram}
                            className="footer-icon-ig"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <FontAwesomeIcon icon={faInstagram} />
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                  <SubscribeDialogBox />
                </div>
              </div>{' '}
              {/* end footer-main-column-mainContent */}
            </div>{' '}
            {/* end footer-main-topRow */}
          </div>{' '}
          {/* end margin-container */}
        </div>{' '}
        {/* end footer-main */}
        <div className="footer-bottom">
          <div className={`margin-container${narrow}`}>
            <div className="footer-bottom-copyright">
              <span>{'\u00a9'}</span>
              {new Date().getFullYear()} Tahoe Art League
            </div>
          </div>
        </div>{' '}
        {/* end footer-bottom */}
      </footer>
    );
  }
}

export default withRouter(Footer);

import React from 'react';
import { StyledTopBarDiv } from './styles/topBarDiv.styled';
import NavBurgerLines from './navBurgerLines';
import { Link } from 'react-router-dom';

const TopBarDiv = React.forwardRef((props, ref) => (
  <>
    <StyledTopBarDiv
      showHide={props.showHide}
      ref={ref}
      height={props.topBarHeight}
      topBarClosed={props.topBarClosed}
      topBarTransition={props.topBarTransition}
    >
      {props.topBarSettings.showTopBar && (
        <div
          style={{
            padding: '0.5em 0.9em',
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'center', // to center item
          }}
          className="margin-container"
        >
          <span
            style={{
              fontSize: '1rem',
              fontWeight: 600,
              lineHeight: '1.4',
              // marginRight: '20px', // if text not centered
              letterSpacing: '.5px',
            }}
          >
            {/* if link present && link to internal site */}
            {props.topBarSettings.linkTo &&
              props.topBarSettings.linkTo.charAt(0) === '/' && (
                <Link className="a-reset" to={props.topBarSettings.linkTo}>
                  {props.topBarSettings.text}
                </Link>
              )}

            {/* if link present && link to external site */}
            {props.topBarSettings.linkTo &&
              props.topBarSettings.linkTo.charAt(0) === 'h' && (
                <a
                  className="a-reset"
                  href={props.topBarSettings.linkTo}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {props.topBarSettings.text}
                </a>
              )}

            {/* if no link */}
            {!props.topBarSettings.linkTo && <>{props.topBarSettings.text}</>}

            {/* if donate link -- if not in use, comment out and uncomment above 3 links */}
            {/* {props.topBarSettings.text && (
              <div className="joinUsColumn-btn joinUsColumn-btn__topBar">
                <form
                  action="https://www.paypal.com/donate"
                  method="post"
                  target="_top"
                >
                  <input
                    type="hidden"
                    name="hosted_button_id"
                    value="UPHDFMH25PMCG"
                  />
                  <input
                    className="joinUs-submit joinUs-submit__topBar"
                    type="submit"
                    value={props.topBarSettings.text}
                  />
                </form>
              </div>
            )} */}
          </span>
          <div
            style={{
              marginTop: '3px',
              // display: 'flex', // if burger should show
              display: 'none', // if burger should not show
            }}
          >
            <NavBurgerLines
              handleTopBarClose={props.handleTopBarClose}
              activeClass={'burgerActive'}
            />
          </div>
        </div>
      )}
    </StyledTopBarDiv>
  </>
));

export default TopBarDiv;

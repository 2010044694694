import React, { Component } from 'react';
import { StyledImageSkeletonOverlay } from './styles/imageSkeletonOverlay.styled';
import ImgNextGen from './imgNextGen';

class HeroImage extends Component {
  state = {
    isRendered: false,
  };

  render() {
    const { image, size } = this.props;

    let heroHeight = 'heroImage-container'; // default

    switch (size) {
      case 'narrow':
        heroHeight = 'heroImage-container hero-narrow';
        break;
      case 'large':
        heroHeight = 'heroImage-container hero-large';
        break;
      default:
        heroHeight = 'heroImage-container';
    }

    const loaded = () => {
      this.setState({ isRendered: true });
    };
    const opacity = this.state.isRendered ? ' ' : 'hideFade';

    return (
      <div className="heroImage">
        <div className={heroHeight}>
          <div className="heroImage-wrap">
            <StyledImageSkeletonOverlay />
            <ImgNextGen
              srcWebp={image.srcWebp}
              fallback={image.src}
              alt={image.alt}
              onLoad={loaded}
              className={`${opacity}`}
            />
            {/* <img src={image.src} alt={image.alt} onLoad={loaded} className={`${opacity}`} /> */}
          </div>
        </div>
      </div>
    );
  }
}

export default HeroImage;

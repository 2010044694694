import styled from 'styled-components';

export const StyledNavMobile = styled.div`
  /* Transition time has to match: StyledTopBarDiv and StyledMain. Also 2 places connected to this timeout in App.js */
  ${({ mainTransition }) =>
    mainTransition &&
    `
    transition: 0.5s;
  `};

  ${({ topBarClosed, mainTopMargin }) =>
    !topBarClosed &&
    `
    top: calc(63px + ${mainTopMargin}px);
  `}
  ${({ topBarClosed }) =>
    topBarClosed &&
    `
    top: 63px;
  `}

  @media (min-width: 340px) {
    ${({ topBarClosed, mainTopMargin }) =>
      !topBarClosed &&
      `
      top: calc(90px + ${mainTopMargin}px);
    `}
    ${({ topBarClosed }) =>
      topBarClosed &&
      `
      top: 90px;
    `}
  }

  @media (min-width: 550px) {
    ${({ topBarClosed, mainTopMargin }) =>
      !topBarClosed &&
      `
      top: calc(118px + ${mainTopMargin}px);
    `}
    ${({ topBarClosed }) =>
      topBarClosed &&
      `
      top: 118px;
    `}
  }

  /* @media (min-width: 1055px) {
    display: none;
  } */
`;

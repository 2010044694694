import React, { Component } from 'react';
// import Crossfade from './crossfade';
// import FeaturedCarts from './featuredCarts';
import { getCrossfadeImagesByName } from './../services/crossfadeService';
import BannerText from './bannerText';
import { getBannerTextByName } from './../services/bannerTextService';
import HeroImage from './heroImage';

class Home extends Component {
  state = {
    crossfadeImages: [],
    bannerText: {
      name: '',
      mainText: '',
      arrowText: '',
      link: '',
      showBanner: true,
    },
  };

  componentDidMount() {
    window.scrollTo(0, 0);

    const setStateCrossfadeImages = async () => {
      const result = await getCrossfadeImagesByName('landing');
      let imgArr = result.data[0].images;

      // Sort images by postion property.
      imgArr.sort((a, b) =>
        a.position > b.position ? 1 : b.position > a.position ? -1 : 0
      );

      this.setState({ crossfadeImages: imgArr });
    };
    setStateCrossfadeImages();

    const getBannerTextThenSetState = async () => {
      const result = await getBannerTextByName('landing');
      const bannerText = result.data;

      this.setState({ bannerText });
    };
    getBannerTextThenSetState();
  }

  render() {
    // const { user } = this.props;
    const { bannerText } = this.state;

    return (
      <React.Fragment>
        {bannerText.name && <BannerText bannerText={bannerText} />}
        <div
          className="margin-container"
          style={{
            margin: '0 auto',
            textAlign: 'center',
            fontWeight: 400,
            lineHeight: '1.4rem',
            marginBottom: '0.7em',
          }}
        >
          Register for free on Facebook by clicking{' '}
          <a
            href="https://www.facebook.com/events/990429562491585/"
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>{' '}
          - and see new work, Artist interviews, behind-the-scenes content and
          more.
        </div>
        <div className="margin-container">
          <HeroImage
            image={{
              // src: 'http://localhost:3400/uploads/hero-narrow.jpg',
              src: 'https://talart.org/uploads/studiotour/hero-narrow.jpg',
              alt: 'bird eying art',
            }}
          />
          {/* <Crossfade crossfadeImages={this.state.crossfadeImages} /> */}
        </div>
        {/* Only render if data fetched. */}
        {/* { bannerText.name && <BannerText bannerText={bannerText} />} */}
        <div className="margin-container">
          <h2 className="studioTourLocations">LOCATIONS</h2>
        </div>
        <div className="margin-container">
          <HeroImage
            image={{
              src: 'https://talart.org/uploads/studiotour/map.jpg',
              alt: 'studio tour location map',
            }}
          />
        </div>
        <div className="margin-container">
          <h2 className="studioTourLocations">ARTISTS</h2>
        </div>
        <div className="margin-container">
          <HeroImage
            image={{
              src: 'https://talart.org/uploads/studiotour/artists.JPG',
              alt: 'studio tour artists',
            }}
            size="large"
          />
        </div>
        {/* <div className="margin-container">
          <FeaturedCarts user={user} />
        </div> */}
        <div className="margin-container">
          <h2 className="studioTourLocations">ABOUT</h2>
        </div>
        <div className="margin-container">
          <p style={{ maxWidth: 600, margin: '0 auto', textAlign: 'justify' }}>
            2024 marks the 17th anniversary of our extraordinary self-guided
            Artist Studio Tour that is held every year on the last weekend of
            July and the first weekend in August. There are multiple studios
            offering a unique opportunity to meet with and buy directly from the
            artists. The Artist Studio Tour includes a wide range of
            photographers, woodworkers, jewelers, painters, printmakers, and
            ceramicists. Be sure to check out this years artists line up. We
            look forward to seeing you at the Tahoe Art League Gallery: 3062
            Lake Tahoe Boulevard in beautiful South Lake Tahoe.
          </p>
        </div>
      </React.Fragment>
    );
  }
}

export default Home;
